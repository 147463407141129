import React, { useEffect, useState } from 'react';
import { Text, makeStyles } from '@knockrentals/knock-shared-web';
import { Toaster } from '@knockrentals/knock-react';
import FeatureDrawer from '../../../Features/common/FeatureDrawer';
import FeatureCard from '../../../Features/common/FeatureCard';
import FeatureCardContent from '../../../Features/common/FeatureCardContent';
import { WarningBanner } from '../../../Features/CallIntelPage/BulkPropertiesUpdateDrawer/WarningBanner';
import { PropertyChips } from '../../../Features/common/PropertyChips';
import AiBulkEditFeatures from './AiBulkEditFeatures';
import { FeatureActions } from '../../../Features/common/FeatureActions';
import CancelBulkEditModal from './CancelBulkEditModal';
import { saveProductsEnabled } from '../../VirtualAgentAPI';
import { editAiEmailEnabled } from '../../aiEmailAPI';
import SaveBulkEditModal from './SaveBulkEditModal';

const useStyles = makeStyles({
  chipsSpacing: {
    marginBottom: '24px'
  },
  editDrawer: {
    width: '600px'
  }
});


const DEFAULT_FEATURE_STATE = [
  { field: "Voice", value: undefined, equal: undefined, db_value: "voice_product_enabled" },
  { field: "Chat", value: undefined, equal: undefined, db_value: "chat_product_enabled" },
  { field: "SMS", value: undefined, equal: undefined, db_value: "sms_product_enabled" },
  { field: "Email", value: undefined, equal: undefined, db_value: "email_product_enabled" },
  { field: 'Nurture', value: undefined, equal: undefined, db_value: 'nurture_product_enabled' }
];
const PRODUCT_ENABLED_FIELDS = ['voice_product_enabled', 'chat_product_enabled', 'sms_product_enabled'];

const AiBulkEditDrawer = ({
  isEditDrawerOpen,
  setIsEditDrawerOpen,
  title,
  properties,
  companyId,
  handleBulkToggle
}) => {
  const classes = useStyles();
  const [featureStates, setFeatureStates] = useState(DEFAULT_FEATURE_STATE);
  const [isChangesMade, setIsChangesMade] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showSaveModal, setSaveModal] = useState(false);

  const checkPropertyValuesEqual = (selectedProperties) => {
    const isVoiceProductEnabledEqual = selectedProperties.every(property => property.voiceProductEnabled === selectedProperties[0].voiceProductEnabled);
    const isChatProductEnabledEqual = selectedProperties.every(property => property.chatProductEnabled === selectedProperties[0].chatProductEnabled);
    const isSmsProductEnabledEqual = selectedProperties.every(property => property.smsProductEnabled === selectedProperties[0].smsProductEnabled);
    const isEmailProductEnabledEqual = selectedProperties.every(property => property.emailProductEnabled === selectedProperties[0].emailProductEnabled);
    if (!isVoiceProductEnabledEqual || !isChatProductEnabledEqual ||
      !isSmsProductEnabledEqual || !isEmailProductEnabledEqual
    ) {
      setShowWarning(true);
    }
    setFeatureStates([
      { field: "Voice", value: selectedProperties[0].voiceProductEnabled, equal: isVoiceProductEnabledEqual, db_value: "voice_product_enabled" },
      { field: "Chat", value: selectedProperties[0].chatProductEnabled, equal: isChatProductEnabledEqual,db_value: "chat_product_enabled" },
      { field: "SMS", value: selectedProperties[0].smsProductEnabled, equal: isSmsProductEnabledEqual, db_value: "sms_product_enabled" },
      { field: "Email", value: selectedProperties[0].emailProductEnabled !== 'OFF', equal: isEmailProductEnabledEqual, db_value: "email_product_enabled" }
    ]);
  };

  const handleEditFeatures = (obj) => {
    const [field, value] = Object.entries(obj)[0];
    const { db_value, equal } = featureStates.find(feature => feature.field === field);
    setIsChangesMade(prevChanges => {
      const existingChange = prevChanges.find(change => change.db_value === db_value);
      if (existingChange && !equal) {
        return prevChanges.filter(change => change.db_value !== db_value);
      } else {
        return [...prevChanges, { db_value, value }];
      }
    });
    setFeatureStates(prevState => 
      prevState.map(feature =>
        feature.field === field ?
          {...feature, value: value, equal: true}
          : feature
      )
    );
  };
  
  const handleCancelBulkEditDrawer = () => {
    if (!isChangesMade.length) {
      setIsEditDrawerOpen(false);
      setShowWarning(false);
    } else {
      setShowModal(true);
    }
  };

  const handleCancelBulkEditModal = (isContinue = false) => {
    setShowModal(false);
    if (isContinue) {
      setIsEditDrawerOpen(false);
      setIsChangesMade([]);
      setShowWarning(false);
    }
  };

  const handleCancelSaveEditModal = () => {
    setSaveModal(false);
  };

  const bulkSave = async () => {
    setSaveModal(false);
    const productEnabledChanges = isChangesMade.some(change =>
      PRODUCT_ENABLED_FIELDS.includes(change.db_value)
    );
    const aiEmailChanges = isChangesMade.some(change => 
      ['email_product_enabled'].includes(change.db_value)
    );
    const productEnabledProperties = properties.map((property) => {
      const base = { property_id: property.propertyId };
      isChangesMade.forEach(change => {
        if (change.db_value !== 'email_product_enabled') {
          base[change.db_value] = change.value;
        }
      });
      return base;
    });
    const aiEmailPayload = properties.map((property) => {
      const base = { property_id: property.propertyId, company_id: companyId };
      isChangesMade.forEach(change => {
        if (change.db_value === 'email_product_enabled') {
          base['ai_email_enabled'] = change.value ? 'SETUP' : 'OFF';
        }
      });
      return base;
    });
    if (productEnabledChanges) {
      const productEnabledPayload = {
        pmc_id: companyId,
        properties: productEnabledProperties
      };
      try {
        await saveProductsEnabled(productEnabledPayload);
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      } catch (error) {
        Toaster.showToast(
          'Error Saving Products',
          2000,
          Toaster.ToastClasses.error
        );
        return;
      }
    }
    if (aiEmailChanges) {
      try {
        await editAiEmailEnabled(aiEmailPayload);
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      } catch (error) {
        Toaster.showToast(
          'Error Saving Products',
          2000,
          Toaster.ToastClasses.error
        );
        return;
      }
    }
    handleBulkToggle(productEnabledProperties, aiEmailPayload);
  };

  const handleBulkSave = () => {
    bulkSave();
    setIsEditDrawerOpen(false);
    setIsChangesMade([]);
    setShowWarning(false);
  };

  useEffect(() => {
    setShowWarning(false);
    if (properties.length) {
      checkPropertyValuesEqual(properties);
    }
  }, [properties]);

  return (
    <FeatureDrawer disableEnforceFocus isOpen={isEditDrawerOpen} className={classes.editDrawer}>
      <section className={classes.editDrawer}>
        <FeatureCard
          closeDrawer={handleCancelBulkEditDrawer}
          subheader={title}
        >
          {showModal && (
            <CancelBulkEditModal isOpen={showModal} handleClose={handleCancelBulkEditModal} />
          )}
          {showSaveModal && (
            <SaveBulkEditModal 
              title={'Update Product Settings'} 
              isOpen={showSaveModal} 
              handleClose={handleCancelSaveEditModal}
              propertyLength={properties.length}
              handleSave={handleBulkSave}
            />
          )}
          <FeatureCardContent>
            {showWarning && <WarningBanner virtualAgent={true}/>}
            <Text variant="body1">Selected Properties</Text>
            <div className={classes.chipsSpacing}>
              <PropertyChips properties={properties} />
            </div>
            <AiBulkEditFeatures featureStates={featureStates} handleEditFeatures={handleEditFeatures} />
          </FeatureCardContent>
          <FeatureActions 
            handleCancelOnClick={handleCancelBulkEditDrawer}
            isSaveButtonDisabled={isChangesMade && !isChangesMade.length}
            handleSaveOnClick={() => setSaveModal(true)}
          />
        </FeatureCard>
      </section>
  </FeatureDrawer>
  );
};

export default AiBulkEditDrawer;
