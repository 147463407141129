import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { AuthenticationService } from '@knockrentals/knock-react';
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { RasterLogo } from './Logo';
import { UnifiedLoginIcon } from './UnifiedLoginIcon';
import useLogin from './useLogin';
import { ENVIRONMENT } from '../../config';

export const DEFAULT_REDIRECT_ROUTE = '/';

const LoginPage = ({ history, location }) => {
  const {
    handleContingentAccessToken,
    handleKnockAccessTokenLogin,
    handleKnockLogin,
    knockAccessToken,
    loading,
    unifiedLoginAuth,
  } = useLogin({ history });

  const redirectToReferrer = () => {
    const to =
      location.state && location.state.from
        ? location.state.from
        : { pathname: DEFAULT_REDIRECT_ROUTE };

    return <Redirect to={to} />;
  };

  const loginSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  if (knockAccessToken && !loading) {
    handleKnockAccessTokenLogin();
  }

  if (!knockAccessToken) {
    handleContingentAccessToken();
  }

  if (AuthenticationService.isAuthenticated()) {
    return redirectToReferrer();
  }

  return (
    <div className="login-page-container">
      <RasterLogo styles={{ marginBottom: '1rem' }} />
      <Typography variant="h5" style={{ marginBottom: '3rem' }}>
        Knock Admin
      </Typography>

      <Formik
        initialValues={{
          password: '',
          username: '',
        }}
        validationSchema={loginSchema}
        onSubmit={handleKnockLogin}
      >
        {({ dirty, isValid }) => (
          <Form className="login-page-form">
            <div className="knock-react-flex knock-react-flex-margin">
              <Field className="knock-react-input" name="username">
                {({ field, meta }) => (
                  <TextField
                    autoFocus
                    error={meta.touched && !!meta.error}
                    label="Username"
                    variant="outlined"
                    fullWidth={true}
                    {...field}
                  />
                )}
              </Field>
            </div>

            <div
              className="knock-react-flex knock-react-flex-margin"
              style={{ margin: '2rem 0' }}
            >
              <Field
                className="knock-react-input"
                name="password"
                placeholder="******"
              >
                {({ field, meta }) => (
                  <TextField
                    aria-label="password"
                    error={meta.touched && !!meta.error}
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth={true}
                    {...field}
                  />
                )}
              </Field>
            </div>

            <Button
              className="knock-react-button"
              type="submit"
              disabled={!dirty || !isValid}
              style={{ width: '100%', margin: 0 }}
            >
              {loading ? (
                <CircularProgress color="white" size="20px" />
              ) : (
                'Log in'
              )}
            </Button>
          </Form>
        )}
      </Formik>

      {ENVIRONMENT !== 'production' && (
        <Button
          onClick={() => {
            unifiedLoginAuth.signinRedirect();
          }}
          variant="outlined"
          style={{ width: '450px', marginTop: '2rem' }}
          startIcon={<UnifiedLoginIcon />}
        >
          Login with RealPage
        </Button>
      )}
    </div>
  );
};

LoginPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(LoginPage);
