import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@knockrentals/knock-shared-web';
import { CampaignDataTable } from './CampaignDataTable';
import { DataTableToolBar } from '../DataTableToolBar';
import AiNurtureContainer from '../AiNurture/AiNurtureContainer';
import { useFeatureFlagContext } from '../../../../../Context/FeatureFlagContext';

export const DESCRIPTION =
  'Campaigns are a collection of automated communications designed to keep prospects engaged and ultimately drive them to contact the property. These Campaigns are fully customizable, with the creator setting the trigger and subsequent Actions.';

const CampaignView = ({
  getTableData,
  rowsPerPage,
  rowsPerPageOptions,
  setCampaign,
  setPageIndex,
  setShouldLoadPageIndex,
  tableRef,
  totalCampaignCount,
}) => {

  const { isAiNurtureEnabled } = useFeatureFlagContext();

  return (
    <section className="campaign-view">
      <div className="view-description">
        <Text variant="caption">{DESCRIPTION}</Text>
      </div>

      {isAiNurtureEnabled && <AiNurtureContainer />}

      <DataTableToolBar
        count={totalCampaignCount}
      />

      <CampaignDataTable
        getTableData={getTableData}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        setCampaign={setCampaign}
        setPageIndex={setPageIndex}
        setShouldLoadPageIndex={setShouldLoadPageIndex}
        tableRef={tableRef}
      />
    </section>
  );
};

CampaignView.propTypes = {
  getTableData: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  setCampaign: PropTypes.func,
  setPageIndex: PropTypes.func,
  setShouldLoadPageIndex: PropTypes.func,
  tableRef: PropTypes.shape({ current: PropTypes.object }),
  totalCampaignCount: PropTypes.number,
};

export default CampaignView;
