import { BASE_WEBAPP_URI } from '../../config';
import { Toaster } from '@knockrentals/knock-react';
import { ADMIN_USER_ROLES } from '../../constants';

export const ERROR_MESSAGES = {
  AGENT_ERROR: 'Agent users cannot log in to the admin web app.',
  ANALYTICS_ERROR:
    'This user does not have the role type to access this application.',
  DEFAULT: 'Error logging in.',
  MAX_ATTEMPTS:
    'Too many failed login attempts. Please try again later, reset your password, or contact Knock support.',
  TOKEN_EXCHANGE: 'An error occurred while trying to log in, please try again.',
  UNIFIED_PLATFORM_MIGRATED_USER_LOGIN_ERROR_MESSAGE:
    'Error logging in. If you have a RealPage account, try logging in from RealPage Unified Platform.',
};

export const getRoleFromToken = (accessToken) =>
  JSON.parse(window.atob(accessToken.split('.')[1])).sub.role;

export const getCookie = function (name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const [key, value] = cookies[i].trim().split('=');
    if (key === name) return value;
  }
};

export const getDomain = (hostname = '') => {
  const hostNameSegments = hostname.split('.');
  return hostNameSegments.slice(-2).join('.');
};

export const deleteAccessTokenCookie = (hostname) => {
  const domainName = getDomain(hostname);
  const domain = domainName ? `domain=${domainName}; ` : '';
  document.cookie = `accessToken=; ${domain}expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
};

export const showToastError = (errorMessage) => {
  Toaster.showToast(errorMessage, 2000, Toaster.ToastClasses.error);
};

export const isUserRoleValid = (role) => {
  if (role !== ADMIN_USER_ROLES.MASTER && role !== ADMIN_USER_ROLES.ADMIN) {
    throw new Error(role);
  }
};

export const handleError = (errorType, username) => {
  switch (errorType) {
    case 403:
      showToastError(
        ERROR_MESSAGES.UNIFIED_PLATFORM_MIGRATED_USER_LOGIN_ERROR_MESSAGE
      );
      break;

    case 409:
      window.location.href = `${BASE_WEBAPP_URI}/change-password?username=${username}&redirect=${window.location.href}`;
      break;

    case 429:
      showToastError(ERROR_MESSAGES.MAX_ATTEMPTS);
      break;

    case 'agent':
      showToastError(ERROR_MESSAGES.AGENT_ERROR);
      break;

    case 'token-exchange':
      showToastError(ERROR_MESSAGES.TOKEN_EXCHANGE);
      break;

    case ADMIN_USER_ROLES.ANALYTICS:
      showToastError(ERROR_MESSAGES.ANALYTICS_ERROR);
      break;

    default:
      showToastError(ERROR_MESSAGES.DEFAULT);
      break;
  }
};
