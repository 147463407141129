import LaunchDarklyClientProvider from 'knock-utils/feature-flag/launch-darkly-client-provider';
import { ClientFeatureFlagService } from 'knock-utils/feature-flag/client-feature-flag-service';
import { LAUNCH_DARKLY_CLIENTSIDE_ID } from '../config';

// This service initializes feature flags using the knock-utils library, an in-house wrapper for our
// external feature flag service. It exports two consts:
// 1) featureFlags, which should expand to contain all featureFlags available to the app, and
// 2) featureFlagService, the wrapper for knock-utils ClientFeatureFlagService.
// Available Methods:
// ** featureFlagService.initialize(new FeatureFlagScope(leasingTeamId)) ** initializes the feature flag for a given scope
// ** featureFlagService.get(featureFlags.flagName, defaultValue) ** will get the value of a flag for that scope.
// https://github.com/knockrentals/knock-utils/blob/master/src/feature-flag/client-feature-flag-service.ts

// Establish featureFlags as an exported const, to avoid string literals in calls to FeatureFlagService.get()
const featureFlags = {
  ADMIN_A2P_CAMPAIGN_REGISTRATION_REMOVAL:
    'admin-a2p-campaign-registration-removal',
  ADMIN_ACCESS_PAGE_ENHANCEMENT: 'admin-access-page-enhancement',
  ADMIN_AI_EMAIL_TRIGGER: 'admin-ai-email-trigger',
  ADMIN_BATCH_SOURCE_COST: 'admin-batch-source-cost',
  ADMIN_BRAND_CONTENT_ENHANCEMENTS: 'admin-brand-content-enhancements',
  ADMIN_COMPANY_TEMPLATES: 'admin-company-templates',
  ADMIN_COMPANY_TEMPLATES_PREVIEW: 'admin-company-templates-preview',
  ADMIN_COMPANY_TEMPLATES_SELF_GUIDED_TOURS:
    'admin-company-templates-self-guided-tours',
  ADMIN_COMPANY_TEMPLATES_WINBACK: 'admin-company-templates-winback',
  ADMIN_CONFIGURABLE_STALE_TRIGGER: 'admin-configurable-stale-trigger',
  ADMIN_DOORWAY_DNI: 'admin-doorway-dni',
  ADMIN_DRIP_SMS: 'admin-drip-sms',
  ADMIN_LRO_SETTINGS: 'admin-lro-settings',
  ADMIN_MARKETING_SOURCE_COST: 'admin-marketing-source-cost',
  ADMIN_OMNIBAR: 'admin-omnibar',
  ADMIN_OMNIBAR_HELP: 'admin-omnibar-help',
  ADMIN_OSM_INTEGRATION: 'admin-osm-integration',
  ADMIN_PHOTOS_MIGRATION: 'admin-photos-migration',
  ADMIN_SSDA_PAGE: 'admin-ssda-page',
  AI_EMAIL_GEN_AI_ADMIN: 'ai-email-gen-ai-admin',
  AI_NURTURE: 'ai-nurture',
  CALL_INTEL_STANDALONE: 'call-intel-standalone',
  COMPANY_SOURCE_LIST: 'company-source-list',
  DISABLE_USER_DELETE: 'disable-user-delete',
  NEW_UNITS: 'new-units',
  QUOTE_UPCHARGES: 'quote-upcharges',
  TOURS_SITE_MAP: 'tours-site-map',
  USE_COOKIE_FOR_TOKEN: 'use-cookie-for-token',
  SHOW_NEW_VA_CONFIGURATION_UI: 'show_new_va_configuration_ui',
};

// Instantiate the knock-utils/ LD Client provider
const provider = new LaunchDarklyClientProvider(LAUNCH_DARKLY_CLIENTSIDE_ID);

// Instantiate ClientFeatureFlagService as featureFlagService
// We can use this to set feature flag scope and get feature flag values throughout the app
const featureFlagService = new ClientFeatureFlagService(provider);

export { featureFlags, featureFlagService };
