import React, { Component } from 'react';
import {
  PageContainer,
  Toaster,
  Alert,
  AuthenticationService,
} from '@knockrentals/knock-react';
import * as _ from 'lodash';
import PropertyInfo from './PropertyInfo';
import AddPropertyModal from './AddPropertyModal';
import PropertyImport from './PropertyImport';
import TransferProperty from './TransferProperty';
import PropertiesAPI from './PropertiesAPI';
import { getPropertyList } from '../../Pages/Features/SchedulingPage/schedulingApi';
import SettingsAPI from '../Settings/SettingsAPI';
import { sortCaseInsensitive } from '../../Utils';
import { getUpdatedPropertyPreferences } from './utils';
import './_propertyInfo.scss';

class PropertiesPage extends Component {
  state = {
    isAddingProperty: false,
    isImportingProperties: false,
    isTransferringProperty: false,
    selectedPropertyId: '',
    selectedTab: 'Basics',
    importErrors: null,
    transferringPropertyErrors: null,
    settings: [],
    propertyInfoMap: {},
    propertyList: [],
    teams: [],
  };

  _savingThreadCount = 0;

  async componentDidMount() {
    await this.loadSettings();
    this.loadPropertyInformation();
    this.loadPropertyTeamUsers();
  }

  async loadSettings() {
    const settings = await SettingsAPI.getSettings()
      .then((response) => response.leasing_team_settings)
      .catch((e) => {
        console.log(e);
      });

    this.setState({ settings });
  }

  loadPropertyTeamUsers() {
    PropertiesAPI.getLeasingTeams()
      .then((response) => {
        const teams = response.leasing_team_users;
        teams.sort((a, b) => a.leasing_team_name > b.leasing_team_name);

        _.forEach(teams, (team) => {
          team.users.sort((a, b) => a.first_name > b.first_name);
        });

        this.setState({ teams });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async loadPropertyInformation() {
    try {
      const responsePropertyList = await getPropertyList();

      const propertyList = responsePropertyList.sort(
        sortCaseInsensitive('name')
      );

      const [{ id: firstPropertyId }] = propertyList;

      const selectedPropertyId =
        this.state.selectedPropertyId || firstPropertyId;

      const { property } = await PropertiesAPI.getPropertyById(
        selectedPropertyId
      );

      const integrityProperty = {
        ...property,
        data: this._enforceCommunityIntegrity(property.data),
      };

      this.setState((prevState) => {
        return {
          propertyInfoMap: {
            ...prevState.propertyInfoMap,
            [property.id]: integrityProperty,
          },
          propertyList,
          selectedPropertyId,
        };
      });
    } catch (error) {
      Toaster.showToast(
        'Error retrieving properties',
        2000,
        Toaster.ToastClasses.error
      );
    }
  }

  openTransferModal() {
    this.setState({
      isTransferringProperty: true,
    });
  }

  render() {
    const {
      isAddingProperty,
      isImportingProperties,
      importErrors,
      teams,
      isTransferringProperty,
      propertyInfoMap,
      selectedPropertyId,
    } = this.state;

    return (
      <PageContainer className="property-info-page-container">
        <h1>Property Information</h1>

        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div className="inline-block side-margin-sm">Property</div>
            <div className="inline-block side-margin-sm">
              <select
                value={selectedPropertyId}
                onChange={this.onPropertyChanged.bind(this)}
              >
                {this.renderPropertyOptions()}
              </select>
            </div>
            {AuthenticationService._internalMode ? (
              <div className="inline-block side-margin-sm">
                <button
                  className="knock-react-button btn-sm btn-success"
                  onClick={this.toggleAddModal.bind(this, true)}
                >
                  <i className="fa fa-lg fa-plus-circle" /> New Property
                </button>
              </div>
            ) : null}

            {AuthenticationService._internalMode ? (
              <div className="inline-block side-margin-sm">
                <button
                  className="knock-react-button btn-sm btn-success"
                  onClick={this.toggleImportModal.bind(this, true)}
                >
                  <i className="fa fa-lg fa-plus-circle" /> Import Properties
                </button>
              </div>
            ) : null}

            {AuthenticationService._internalMode ? (
              <div className="inline-block side-margin-sm">
                <button
                  className="knock-react-button btn-sm btn-success"
                  onClick={this.openTransferModal.bind(this, true)}
                >
                  <i className="fa fa-lg fa-plus-circle" /> Transfer this
                  property
                </button>
              </div>
            ) : null}
          </div>
        </div>

        {this.renderProperties()}

        {isAddingProperty ? (
          <AddPropertyModal
            createProperty={this.createProperty.bind(this)}
            teams={teams}
            toggleModal={this.toggleAddModal.bind(this)}
          />
        ) : null}

        {isImportingProperties ? (
          <PropertyImport
            importErrors={importErrors}
            onCancel={() => {
              this.setState({ isImportingProperties: false });
            }}
            onImportProperties={this.onImportProperties.bind(this)}
            toggleModal={this.toggleImportModal.bind(this)}
          />
        ) : null}

        {isTransferringProperty ? (
          <TransferProperty
            onCancel={() => {
              this.setState({ isTransferringProperty: false });
            }}
            onSubmit={this.submitTransferProperty.bind(this)}
            property={propertyInfoMap[selectedPropertyId]}
          />
        ) : null}
      </PageContainer>
    );
  }

  async onPropertyChanged(event) {
    const selectedPropertyId = event.target.value;

    const selectedProperty = this.state.propertyInfoMap[selectedPropertyId];

    if (selectedProperty) {
      this.setState({ selectedPropertyId });
      return;
    }

    try {
      const { property } = await PropertiesAPI.getPropertyById(
        selectedPropertyId
      );

      const integrityProperty = {
        ...property,
        data: this._enforceCommunityIntegrity(property.data),
      };

      this.setState((prevState) => {
        return {
          selectedPropertyId: property.id,
          propertyInfoMap: {
            ...prevState.propertyInfoMap,
            [property.id]: integrityProperty,
          },
        };
      });
    } catch (error) {
      Toaster.showToast(
        'Error to get a property',
        2000,
        Toaster.ToastClasses.error
      );
    }
  }

  renderPropertyOptions() {
    return this.state.propertyList.map((property) => {
      return (
        <option key={'property-' + property.id} value={property.id}>
          {property.name}
        </option>
      );
    });
  }

  renderProperties() {
    if (this.state.propertyList.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    const selectedProperty =
      this.state.propertyInfoMap[this.state.selectedPropertyId];

    return (
      <PropertyInfo
        changeSelectedTab={this.changeSelectedTab.bind(this)}
        onInformationChanged={this.onInformationChanged.bind(this)}
        property={selectedProperty}
        savePropertyPreference={this.savePropertyPreference}
        selectedTab={this.state.selectedTab}
        settings={this.state.settings}
        teams={this.state.teams}
        updatePropertyPhotos={this.updatePropertyPhotos}
        updatePropertyPreference={this.updatePropertyPreference}
        updateSettings={this.updateSettings.bind(this)}
      />
    );
  }

  toggleAddModal(isOpen) {
    this.setState({
      isAddingProperty: isOpen,
    });
  }

  toggleImportModal(isOpen) {
    this.setState({
      isImportingProperties: isOpen,
    });
  }

  createProperty(managerId, community) {
    this._incrementSavingThreadCount();
    this._handleSavingToaster();

    PropertiesAPI.createPropertyByCommunity(managerId, community)
      .then(() => {
        this.loadPropertyInformation();

        this._decrementSavingThreadCount();

        this._handleSavingToaster();
        Toaster.showToast('Created!', 2000, Toaster.ToastClasses.success);
      })
      .catch((e) => {
        this._decrementSavingThreadCount();

        this._handleSavingToaster();
        Toaster.showToast(
          'Error creating property.',
          2000,
          Toaster.ToastClasses.error
        );
        console.log(e);
      });
  }

  onImportProperties(propertiesFile) {
    const data = new FormData();
    data.append('properties-import', propertiesFile, 'properties-import.csv');

    return PropertiesAPI.importProperties(data)
      .then((response) => {
        if (response.is_invalid) {
          const importErrors = {
            errorMessages: response.error_messages,
            duplicatePropertyMailerUsernames:
              response.duplicate_property_mailer_usernames,
            invalidOwnersUsernames: response.invalid_owners_usernames,
            existingPropertyMailerUsernames:
              response.existing_property_mailer_usernames,
          };

          this.setState({ importErrors });

          Toaster.showToast(
            'Error importing properties',
            2000,
            Toaster.ToastClasses.error
          );
        } else {
          Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

          this.setState({ isImportingProperties: false, importErrors: null });
          //this.load(); FIXME uncomment this when you figure out why `this` is a different `this`
        }
      })
      .catch(() => {
        Toaster.showToast(
          'Error importing properties',
          2000,
          Toaster.ToastClasses.error
        );

        this.setState({ importErrors: null });
      });
  }

  async submitTransferProperty(
    leasingTeamPublicId,
    defaultManagerOwnerUsername
  ) {
    try {
      await PropertiesAPI.transferProperty(
        this.state.selectedPropertyId,
        leasingTeamPublicId,
        defaultManagerOwnerUsername
      );
      Toaster.showToast('Transferred!', 2000, Toaster.ToastClasses.success);
      this.setState({ isTransferringProperty: false });
    } catch (err) {
      Toaster.showToast(
        'Error transferring property',
        2000,
        Toaster.ToastClasses.error
      );
      this.setState({ isTransferringProperty: false });
    }
  }

  changeSelectedTab(tabValue) {
    this.setState({
      selectedTab: tabValue,
    });
  }

  handleSavePropertyError = () => {
    this._decrementSavingThreadCount();

    this._handleSavingToaster();
    Toaster.showToast(
      'Error saving property info.',
      2000,
      Toaster.ToastClasses.error
    );
  };

  async onInformationChanged(updatedProperty) {
    try {
      await this.savePropertyUpdate(updatedProperty);

      this.setState((prevState) => {
        return {
          selectedPropertyId: updatedProperty.id,
          propertyInfoMap: {
            ...prevState.propertyInfoMap,
            [updatedProperty.id]: updatedProperty,
          },
        };
      });
    } catch (error) {
      this.handleSavePropertyError();
    }
  }

  savePropertyUpdate = async (updatedProperty) => {
    this._incrementSavingThreadCount();
    this._handleSavingToaster();

    await PropertiesAPI.updateProperty(
      updatedProperty.data.property_id,
      updatedProperty
    );

    this._decrementSavingThreadCount();
    this._handleSavingToaster();

    if (this._savingThreadCount === 0) {
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
    }
  };

  updatePropertyPreference = (settingName, value, isChildPreference) => {
    this.setState((prevState) => {
      const propertyId = prevState.selectedPropertyId;
      const property = prevState.propertyInfoMap[propertyId];
      const preferences = getUpdatedPropertyPreferences({
        isChildPreference,
        propertyPreferences: property.preferences,
        settingName,
        value,
      });

      return {
        propertyInfoMap: {
          ...prevState.propertyInfoMap,
          [propertyId]: {
            ...property,
            preferences,
          },
        },
      };
    });
  };

  savePropertyPreference = async (settingName, value, isChildPreference) => {
    const propertyId = this.state.selectedPropertyId;
    const property = this.state.propertyInfoMap[propertyId];
    const updatedPropertyPreferences = getUpdatedPropertyPreferences({
      isChildPreference,
      propertyPreferences: property.preferences,
      settingName,
      value,
    });

    try {
      await this.savePropertyUpdate({
        ...property,
        preferences: updatedPropertyPreferences,
      });
    } catch (error) {
      this.handleSavePropertyError();
      throw new Error();
    }
  };

  async updateSettings(groupId, updatedSettings) {
    if (!groupId || !updatedSettings) {
      Toaster.showToast(
        'Error updating nearby communities settings',
        2000,
        Toaster.ToastClasses.error
      );
    } else {
      await SettingsAPI.updateGroupSettings(groupId, updatedSettings).catch(
        (e) => {
          Toaster.showToast(
            'Error updating nearby communities settings',
            2000,
            Toaster.ToastClasses.error
          );
          console.log(e);
        }
      );

      const settings = await this.loadSettings();

      this.setState({ settings });
    }
  }

  updatePropertyPhotos = (photos) => {
    this.setState((prevState) => {
      const propertyId = prevState.selectedPropertyId;
      const property = prevState.propertyInfoMap[propertyId];

      return {
        propertyInfoMap: {
          ...prevState.propertyInfoMap,
          [propertyId]: {
            ...property,
            data: {
              ...property.data,
              photos,
            },
          },
        },
      };
    });
  };

  _enforceCommunityIntegrity(community) {
    const knownObjects = [
      'amenities',
      'laundry',
      'description',
      'extra',
      'parking',
      'utilities',
      'location',
      'pets',
      'social',
      'leasing',
      'coverPhoto',
      'affordableHousing',
      'doorway',
    ];
    _.forEach(knownObjects, (knownObject) => {
      if (!community[knownObject]) {
        community[knownObject] = {};
      }
    });

    community.leasing.terms = community.leasing.terms
      ? community.leasing.terms
      : {};
    community.leasing.application = community.leasing.application
      ? community.leasing.application
      : {};

    const knownArrays = [
      'logos',
      'appliances',
      'videos',
      'views',
      'photos',
      'floorplans',
      'furnishing',
      'costables',
      'customDetails',
    ];

    _.forEach(knownArrays, (knownArray) => {
      if (!community[knownArray]) {
        community[knownArray] = [];
      }
    });

    return community;
  }

  _handleSavingToaster() {
    if (this._savingThreadCount > 0) {
      const savingContent = (
        <span>
          <i className="fa fa-lg fa-spin fa-cog" /> Saving now...
        </span>
      );
      Toaster._fireShowToastHandlers(
        savingContent,
        Toaster.ToastClasses.success
      );
    } else {
      Toaster._fireHideToastHandlers();
    }
  }

  _incrementSavingThreadCount() {
    this._savingThreadCount++;
  }

  _decrementSavingThreadCount() {
    this._savingThreadCount--;
  }
}

export default PropertiesPage;
