import {
  featureFlagService,
  featureFlags,
} from '../../Services/FeatureFlagService';
import FeatureFlagScope from 'knock-utils/feature-flag/feature-flag-scope';

const {
  ADMIN_A2P_CAMPAIGN_REGISTRATION_REMOVAL,
  ADMIN_ACCESS_PAGE_ENHANCEMENT,
  ADMIN_AI_EMAIL_TRIGGER,
  ADMIN_BATCH_SOURCE_COST,
  ADMIN_BRAND_CONTENT_ENHANCEMENTS,
  ADMIN_COMPANY_TEMPLATES,
  ADMIN_COMPANY_TEMPLATES_PREVIEW,
  ADMIN_COMPANY_TEMPLATES_SELF_GUIDED_TOURS,
  ADMIN_COMPANY_TEMPLATES_WINBACK,
  ADMIN_CONFIGURABLE_STALE_TRIGGER,
  ADMIN_DOORWAY_DNI,
  ADMIN_DRIP_SMS,
  ADMIN_LRO_SETTINGS,
  ADMIN_MARKETING_SOURCE_COST,
  ADMIN_OMNIBAR,
  ADMIN_OMNIBAR_HELP,
  ADMIN_OSM_INTEGRATION,
  ADMIN_PHOTOS_MIGRATION,
  ADMIN_SSDA_PAGE,
  AI_EMAIL_GEN_AI_ADMIN,
  AI_NURTURE,
  CALL_INTEL_STANDALONE,
  COMPANY_SOURCE_LIST,
  DISABLE_USER_DELETE,
  NEW_UNITS,
  QUOTE_UPCHARGES,
  SHOW_NEW_VA_CONFIGURATION_UI,
  TOURS_SITE_MAP,
  USE_COOKIE_FOR_TOKEN,
} = featureFlags;

export const featureFlagConfig = {
  [ADMIN_A2P_CAMPAIGN_REGISTRATION_REMOVAL]: {
    isMessagingServiceRegistrationEnabled: false,
  },
  [ADMIN_ACCESS_PAGE_ENHANCEMENT]: {
    isEnhancedAccessEnabled: false,
  },
  [ADMIN_AI_EMAIL_TRIGGER]: {
    isAiEmailTriggerEnabled: false,
  },
  [ADMIN_BATCH_SOURCE_COST]: {
    isBatchSourceCostEnabled: false,
  },
  [ADMIN_BRAND_CONTENT_ENHANCEMENTS]: {
    isEnhancedBrandContentEnabled: false,
  },
  [ADMIN_COMPANY_TEMPLATES]: {
    isCompanyTemplatesEnabled: false,
  },
  [ADMIN_COMPANY_TEMPLATES_PREVIEW]: {
    isCompanyTemplatesPreviewEnabled: false,
  },
  [ADMIN_COMPANY_TEMPLATES_SELF_GUIDED_TOURS]: {
    isCompanyTemplatesSelfGuidedToursEnabled: false,
  },
  [ADMIN_COMPANY_TEMPLATES_WINBACK]: {
    isCompanyTemplatesWinbackEnabled: false,
  },
  [ADMIN_CONFIGURABLE_STALE_TRIGGER]: {
    isConfigurableStaleTriggerEnabled: false,
  },
  [ADMIN_DOORWAY_DNI]: {
    isDoorwayDniEnabled: false,
  },
  [ADMIN_DRIP_SMS]: {
    isDripSmsEnabled: false,
  },
  [ADMIN_LRO_SETTINGS]: {
    isAdminLROSettingsEnabled: false,
  },
  [ADMIN_MARKETING_SOURCE_COST]: {
    isSourcesMarketingSourceCostEnabled: false,
  },
  [ADMIN_OMNIBAR]: {
    isOmnibarEnabled: false,
  },
  [ADMIN_OMNIBAR_HELP]: {
    isOmnibarHelpEnabled: false,
  },
  [ADMIN_OSM_INTEGRATION]: {
    isOSMEnabled: false,
  },
  [ADMIN_PHOTOS_MIGRATION]: {
    isAdminPhotosTabEnabled: false,
  },
  [ADMIN_SSDA_PAGE]: {
    isSSDAPageEnabled: false,
  },
  [AI_EMAIL_GEN_AI_ADMIN]: {
    isAiEmailGenAiAdminEnabled: false,
  },
  [AI_NURTURE]: {
    isAiNurtureEnabled: false,
  },
  [CALL_INTEL_STANDALONE]: {
    isCallIntelStandaloneEnabled: false,
  },
  [COMPANY_SOURCE_LIST]: {
    isCompanySourceListEnabled: false,
  },
  [DISABLE_USER_DELETE]: {
    isUserDeleteDisabled: false,
  },
  [NEW_UNITS]: {
    newUnitsFlags: false,
  },
  [QUOTE_UPCHARGES]: {
    quoteUpchargesFlags: false,
  },
  [SHOW_NEW_VA_CONFIGURATION_UI]: {
    isShowNewVAConfigurationUiEnabled: false,
  },
  [TOURS_SITE_MAP]: {
    isToursSiteMapEnabled: false,
  },
  [USE_COOKIE_FOR_TOKEN]: {
    isUseCookieForTokenEnabled: false,
  },
};

export const initialFeatureFlags = [
  ADMIN_A2P_CAMPAIGN_REGISTRATION_REMOVAL,
  ADMIN_ACCESS_PAGE_ENHANCEMENT,
  ADMIN_AI_EMAIL_TRIGGER,
  ADMIN_BATCH_SOURCE_COST,
  ADMIN_BRAND_CONTENT_ENHANCEMENTS,
  ADMIN_COMPANY_TEMPLATES,
  ADMIN_COMPANY_TEMPLATES_PREVIEW,
  ADMIN_COMPANY_TEMPLATES_WINBACK,
  ADMIN_CONFIGURABLE_STALE_TRIGGER,
  ADMIN_DOORWAY_DNI,
  ADMIN_DRIP_SMS,
  ADMIN_LRO_SETTINGS,
  ADMIN_MARKETING_SOURCE_COST,
  ADMIN_OMNIBAR,
  ADMIN_OMNIBAR_HELP,
  ADMIN_OSM_INTEGRATION,
  ADMIN_PHOTOS_MIGRATION,
  ADMIN_SSDA_PAGE,
  AI_EMAIL_GEN_AI_ADMIN,
  AI_NURTURE,
  CALL_INTEL_STANDALONE,
  COMPANY_SOURCE_LIST,
  DISABLE_USER_DELETE,
  ADMIN_COMPANY_TEMPLATES_SELF_GUIDED_TOURS,
  SHOW_NEW_VA_CONFIGURATION_UI,
  TOURS_SITE_MAP,
  USE_COOKIE_FOR_TOKEN,
];

export const initializeFeatureFlagService = async (scope, companyId) => {
  return await featureFlagService.initialize(
    new FeatureFlagScope(scope, {
      company: companyId,
      service: 'knock-admin-web',
    })
  );
};

export const getFeatureFlag = async (featureFlagName) => {
  const [flagConfig] = Object.entries(featureFlagConfig[featureFlagName]);
  const [flagKey, defaultValue] = flagConfig;
  const flagValue = await featureFlagService.get(featureFlagName, defaultValue);
  return [flagKey, flagValue];
};

export const getInitialFeatureFlags = () =>
  initialFeatureFlags.reduce(async (featureFlags, featureFlagName) => {
    const previousFeatureFlags = await featureFlags;

    const [flagKey, flagValue] = await getFeatureFlag(featureFlagName);
    previousFeatureFlags[flagKey] = flagValue;

    return previousFeatureFlags;
  }, Promise.resolve({}));
