import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import PhotosDialogModal from '../../Photos/PhotosDialogModal';
import PropertyPhotos from './PropertyPhotos';
import '../../Photos/_propertyPhotos.scss';

const PhotosTab = ({ property, updatePropertyPhotos }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [dialogConfig, setDialogConfig] = useState(null);

  const setDialog = (title, body, onConfirm, onCancel) => {
    setDialogConfig({ title, body, onConfirm, onCancel });
  };

  return (
    <div className="property-photos-main">
      <h1>Photos</h1>

      {previewImage && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setPreviewImage(null)}
        />
      )}

      {dialogConfig && (
        <PhotosDialogModal
          body={dialogConfig.body}
          clearDialog={() => setDialogConfig(null)}
          onCancel={dialogConfig.onCancel}
          onConfirm={dialogConfig.onConfirm}
          title={dialogConfig.title}
        />
      )}

      <PropertyPhotos
        property={property}
        setDialog={setDialog}
        setImageToPreview={setPreviewImage}
        updatePropertyPhotos={updatePropertyPhotos}
      />
    </div>
  );
};

PhotosTab.propTypes = {
  property: PropTypes.shape({
    data: PropTypes.shape({
      coverPhoto: PropTypes.object,
      photos: PropTypes.arrayOf(
        PropTypes.shape({
          clUrl: PropTypes.string,
          thumbUrl: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    }),
    id: PropTypes.number,
    property_name: PropTypes.string,
  }),
  updatePropertyPhotos: PropTypes.func,
};
export default PhotosTab;
