import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Tooltip, Box, Text } from '@knockrentals/knock-shared-web';
import { CheckCircle, Cancel, HelpOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    padding: '8px 16px',
  },
  headerListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  textCaption: {
    fontWeight: 600,
  },
  bodyText: {
    fontWeight: 400,
  },
  checkIcon: {
    color: theme.palette.success.light,
  },
  cancelIcon: {
    color: theme.palette.error.main,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '80px',
    padding: '8px 16px',
  },
}));

const AI_NURTURE_ENABLED_TOOLTIP_TEXT =
  'Indicates if AI Nurture is enabled for property';

const PROPERTIES = [
  { name: 'Aston Villas', isAiNurtureEnabled: true },
  { name: 'Bristol Yards', isAiNurtureEnabled: true },
  { name: 'Chelsea Village', isAiNurtureEnabled: false },
  { name: 'Everton Lofts', isAiNurtureEnabled: false },
  { name: 'Forest Hills', isAiNurtureEnabled: true },
  { name: 'Lester Court', isAiNurtureEnabled: false },
  { name: 'Manchester Lofts', isAiNurtureEnabled: true },
  { name: 'Newcastle Park', isAiNurtureEnabled: true },
  { name: 'South Heights', isAiNurtureEnabled: false },
  { name: 'The Brentford Gates', isAiNurtureEnabled: true },
  { name: 'The Cove at Brighton', isAiNurtureEnabled: true },
];

const AiNurturePropertyTable = () => {
  const classes = useStyles();
  return (
    <List disablePadding className={classes.listContainer}>
      <ListItem divider className={classes.headerListItem}>
        <ListItemText>
          <Text variant="caption" className={classes.textCaption}>
            Property
          </Text>
        </ListItemText>
        <Box className={classes.statusContainer}>
          <Text variant="caption" className={classes.textCaption}>
            AI Nurture Enabled
          </Text>
          <Tooltip title={AI_NURTURE_ENABLED_TOOLTIP_TEXT} arrow>
            <HelpOutline fontSize="small" aria-label="tooltip-icon" />
          </Tooltip>
        </Box>
      </ListItem>

      {PROPERTIES.map((property, index) => (
        <ListItem key={property.name} divider={index < PROPERTIES.length - 1}>
          <ListItemText>
            <Text variant="body2" className={classes.bodyText}>
              {property.name}
            </Text>
          </ListItemText>
          <Box className={classes.statusContainer}>
            {property.isAiNurtureEnabled ? (
              <CheckCircle className={classes.checkIcon} />
            ) : (
              <Cancel className={classes.cancelIcon} />
            )}
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default AiNurturePropertyTable;
